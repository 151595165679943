import {reactive, toRefs, onBeforeMount, onMounted, getCurrentInstance, nextTick, defineComponent, inject, computed} from 'vue'
import CardEngineUtil,{ICardEngineDataObj} from "@/components/base_comp/cardEngine/cardEngineUtil";
import EngineUtil from "@/components/base_comp/cardEngine/engineUtil";
import EngineCommon from "@/components/base_comp/cardEngine/engineCommon";
const CardEngineHelper = defineComponent({
    name: 'CardEngine',
    props: {
        //调用dialog的时候外界传给dialog的参数里面有一部分参数会被dialog传给engine组件，详情参看Dialog.vue处
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;//访问全局对象
        const engineParams=props.engineParams as any;//外界传到引擎的参数（调用dialog组件传入的参数）
        let dialogInst = inject('dialogInst') as any;
        let dataObj:ICardEngineDataObj=reactive<ICardEngineDataObj>({
            utilInst:{} as any,
            engineUtil:{} as any,
            refMap:new Map(),
            id:'',//当前正在操作的卡片id，比如删除的时候就会用到
            mainMaxHeight:400,//默认内容的最大高度，实际上在mounted中会动态计算出来
            loading:false,//打开的时候正在加载效果，没有用指令方式是想局部显示正在加载效果
            buttons:[],//头部按钮
            modelComp:engineParams.modelComp,//本次引擎将包含的组件(外界传入的组件)
            modelCompRef: {},//cardEngine包含的组件ref,系统自动赋值
            formRef:null,
            form:{},
            otherParams:{
                compParams:{
                    showBackTarget:true,
                    saveClose:true,
                    isEnginePage:false,
                    colNum:1,
                    rules:{},
                    disabled:false,
                    hasDetails: false,
                    oneDetail:true
                }
            }
        })
        onBeforeMount(()=>{
            dataObj.engineUtil=new EngineUtil(dataObj,proxy,utils);
            dataObj.utilInst=new CardEngineUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            await nextTick(async () => {
                dataObj.utilInst.initDataObjParams();
                //执行新增或者编辑方法，请求后台，为卡片form表单赋值
                await dataObj.engineUtil.doAddOrLoad(engineParams.id,'card');
                await dataObj.engineUtil.animation();
                if(dataObj.otherParams.compParams.detailInfos && Object.keys(dataObj.otherParams.compParams.detailInfos).length>1)dataObj.otherParams.compParams.oneDetail=false;
            })
        })
        //按钮事件
        const clickHandler=(ev:string)=>{
            //如果cardEngine包含的卡片组件有定义该事件，则调用卡片自身的该事件，没有定义则采用cardEngine中默认定义好的事件
            if (EngineCommon.getModelCompRef(proxy)[ev]) {
                EngineCommon.getModelCompRef(proxy)[ev](proxy);
            } else {
                proxy[ev]();
            }
        }

        //保存事件
        const saveHandler=async ()=>{
            await dataObj.engineUtil.saveHandler(props.engineParams,'card',dialogInst)
        }
        //重置事件
        const resetHandler=async()=>{
            await dataObj.engineUtil.resetHandler();
        }

        //删除事件
        const delHandler=async ()=>{
            let id=EngineCommon.getModelCompRef(proxy).form.id;
            // await dataObj.engineUtil.delHandler(engineParams,dataObj.id,dialogInst);
            await dataObj.engineUtil.delHandler(engineParams,id,dialogInst);
        }
        //当采用引擎作为页面的时候，明细表格要传递的参数
        const detailParam=computed(()=>{
            return (options:any) => {
                return {
                    detailParam: {
                        title:options.title,
                        readOnly:dataObj.otherParams.compParams.disabled,
                        canPage: false,
                        queryParam: {
                            detailType: options.detailType
                        },
                        modelMethod: dataObj.otherParams.compParams.modelPath+"/detail?t="+Math.random()*1000
                    }
                }
            }
        })
        return{
            ...toRefs(dataObj),clickHandler,saveHandler,resetHandler,delHandler,detailParam
        }
    }
});
export default CardEngineHelper;